<template>
  <div class="wrapper" id="app" v-bind:data-ajax_count="$root.ajax_count">
    <component v-if="style"
      v-bind:is="'layout-' + style"
      v-bind:user_session="user_session"
      v-bind:apartments="apartments"
      v-bind:buildings="buildings"
      v-bind:holiday="holiday"
      v-on:logout="logout"
    />
  </div>
</template>

<script>
import LayoutDefault from '@/layout/default.vue';
import LayoutGuest from '@/layout/guest.vue';
import LayoutForbidden from '@/layout/forbidden.vue';
import LayoutPrint from '@/layout/print.vue';
import {DateTime} from 'luxon'
import { useMessage } from '@/composables/message';
import { useApartment } from '@/composables/apartment';

export default {
  setup() {
    const { pushMessage } = useMessage()
    const { reflect_apartment } = useApartment()
    return { pushMessage, reflect_apartment }
  },
  components: {
    LayoutDefault,
    LayoutGuest,
    LayoutForbidden,
    LayoutPrint,
  },
  data: function () {
    return {
      user_session: null,
      apartments: null,
      buildings: null,
      holiday: {},
      error: null,
      style: null,
      dragFlag: false,
      dragCancelFlag: null,
      ajax_count: 0,
    }
  },
  methods: {
    init: function () {
      document.title = 'Mcloud | ' + this.$route.meta.title;
      switch (this.$route.meta.login) {
        // 非会員専用ページ
        case 'guest': {
          // ユーザーセッションを持つ場合はトップページへ強制遷移
          if (this.user_session) {
            this.$router.replace('/');
          } else {
            this.style = this.$route.meta.layout || 'guest';
          }
          break;
        }
        // 会員専用・非会員
        case 'all': {
          if (!this.user_session) {
            this.getSession(function () {
              this.style = this.$route.meta.layout || 'guest';
            }.bind(this));
          } else {
            this.style = this.$route.meta.layout || 'default';
            this.checkRoles();
            this.checkUse();
          }
          break;
        }
        // 会員専用ページ
        default: {
          if (!this.user_session) {
            this.getSession(function () {
              if (this.$route.name != 'A-0') {
                window.sessionStorage.setItem('last_access_url', this.$route.fullPath);
              }
              this.user_session = null;
              location.href = '/auth/auth0/sso';
            }.bind(this));
          } else {
            this.style = this.$route.meta.layout || 'default';
            this.checkRoles();
            this.checkUse();
          }
          break;
        }
      }
    },
    getSession: function (fn) {
      this.get('/user_session', function (res) {
        this.style = this.$route.meta.layout || 'default';
        if (res.detail.data) {
          this.user_session = res.detail.data;
          var apartments = new Promise(function (resolve, reject) {
            // マンション情報取得
            this.get('/apartments/' + this.user_session.attributes.apartment_id, function (res) {
              if (res.detail.data) {
                this.apartments = res.detail.data;
                resolve();
              } else {
                if ([1,10,11].includes(this.user_session.attributes.role_id)) {
                  location.href = '/admin/portal';
                } else if ([12,13].includes(this.user_session.attributes.role_id)) {
                  location.href = '/maint/portal';
                }
                reject('アパート情報の取得に失敗');
              }
            });
          }.bind(this));
          var buildings = new Promise(function (resolve, reject) {
            // 棟情報取得
            this.get('/buildings', function (res) {
              if (res.detail.data) {
                this.buildings = res.detail.data;
                resolve();
              } else {
                reject('棟情報の取得に失敗');
              }
            });
          }.bind(this));
          var events = new Promise(function (resolve) {
            // 祝祭日情報取得
            this.get('/schedule/events/holidays', function (res) {
              res.detail.data.forEach(function (params) {
                this.holiday[DateTime.fromISO(params.attributes.begins_at).toISODate()] = params.attributes.title;
              }.bind(this));
              resolve();
            });
          }.bind(this));
          Promise.all([apartments, buildings, events]).then(function () {
            this.checkRoles();
            this.checkUse();
            var redirect_target_path = window.sessionStorage.getItem('redirect_target_path');
            var last_access_url = window.sessionStorage.getItem('last_access_url');
            if (redirect_target_path || last_access_url) {
              window.sessionStorage.removeItem('redirect_target_path');
              window.sessionStorage.removeItem('last_access_url');
              if (redirect_target_path) {
                // リダイレクト先URL情報を持っていればページ移動させる
                location.href = redirect_target_path;
              } else {
                // ログイン前ページ情報を持っていればそこへ遷移
                this.$router.replace(last_access_url, () => {}, () => {});
              }
            }
          }.bind(this)).catch(function (error) {
            this.user_session = null;
            this.pushMessage(error);
            this.$router.replace({name: 'A-0'});
          }.bind(this));
        } else {
          fn.call();
        }
      }, false);
    },
    // 利用チェック
    checkUse: function () {
      if (this.$route.meta.use) {
        if (!this.apartments.attributes[this.$route.meta.use]) {
          this.style = 'forbidden';
        }
      }
    },
    // 権限チェック
    checkRoles: function () {
      if (!(this.$route.meta.roles || [1,2,3,4,5,6,7,8,9,10,11,12,13]).includes(this.user_session.attributes.role_id)) {
        this.style = 'forbidden';
      }
    },
    // ログアウト
    logout: function () {
      if (confirm('ログアウトしますか？')) {
        var role_id = this.user_session.attributes.role_id;
        this.get('/user_sessions/destroy', function (res) {
          if (res.detail.status_code == 204) {
            let logoutUrl = this.user_session.attributes.auth0_logout_url;
            this.user_session = null;
            this.apartments = null;
            this.buildings = null;
            if (logoutUrl) {
              location.href = logoutUrl;
            } else if ([1,10,11].includes(role_id)) {
              location.href = '/admin/portal';
            } else if ([12,13].includes(role_id)) {
              location.href = '/maint/portal';
            } else {
              location.href = '/user_session/new';
            }
            alert('ログアウトしました。');
          }
        });
      }
    },
  },
  computed: {
    routeQuery: function(){
      return this.$route.params
    }
  },
  watch: {
    routeQuery: function(){
      this.init();
      window.scrollTo(0, 0);
    },
    reflect_apartment: function() {
      if (this.reflect_apartment == true) {
        this.getSession(() => {})
        this.reflect_apartment = false
      }
    }
  },
}
</script>
