import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import List from '@ckeditor/ckeditor5-list/src/list';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Blockquote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import Fontcolor from '@ckeditor/ckeditor5-font/src/fontcolor';
import Fontsize from '@ckeditor/ckeditor5-font/src/fontsize';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Horizontalline from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';

import Tooltip from '@/components/tooltip.vue';
import Icon from '@/components/icon.vue';

import {DateTime} from 'luxon';

export default {
  components: {
    Tooltip,
    Icon,
  },
  methods: {
    get: function (url, call, redirect_login = true) {
      var xhr = new XMLHttpRequest();
      xhr.addEventListener('error', function () {
        var res = xhr.response || {};
        res.status_code = xhr.status;
        if (res.status_code == 401) {
          this.relogin();
        }
        if (this.user_session) {
          if (res.status_code == 403 && this.user_session.attributes && this.user_session.attributes.role_id == 13){
            this.not_in_charge_apartment();
          }
        }
        res.error = {
          messages: ['サーバーエラー'],
        };
        if (call) {
          call.apply(this, [{detail: res}]);
        }
        this.$root['ajax_count'] = this.$root.ajax_count - 1
      }.bind(this));
      xhr.addEventListener('load', function () {
        var res = xhr.response || {};
        res.status_code = xhr.status;
        if (res.status_code == 401 && redirect_login) {
          this.relogin();
        }
        if (this.user_session) {
          if (res.status_code == 403 && this.user_session.attributes && this.user_session.attributes.role_id == 13){
            this.not_in_charge_apartment();
          }
        }
        this.$root['ajax_count'] = this.$root.ajax_count - 1
        if (res.errors == undefined) {
          var included = {};
          if (res.included) {
            res.included.forEach(function (inc) {
              included[inc.type] = included[inc.type] || {};
              included[inc.type][inc.id] = inc;
            });
            Object.keys(included).forEach(function (key) {
              Object.keys(included[key]).forEach(function (id) {
                if (included[key][id].relationships) {
                  Object.keys(included[key][id].relationships).forEach(function (rel) {
                    (Array.isArray(included[key][id].relationships[rel].data) ? included[key][id].relationships[rel].data : [included[key][id].relationships[rel].data]).forEach(function (data) {
                      if (data) {
                        if (included[data.type] && included[data.type][data.id]){
                          if (included[data.type][data.id].attributes) {
                            data.attributes = included[data.type][data.id].attributes;
                          }
                          if (included[data.type][data.id].relationships) {
                            data.relationships = included[data.type][data.id].relationships;
                          }
                          if (included[data.type][data.id].meta) {
                            data.meta = included[data.type][data.id].meta;
                          }
                        }
                      }
                    });
                  });
                }
              });
            });
            (Array.isArray(res.data) ? res.data : [res.data]).forEach(function (data) {
              if (data.relationships) {
                Object.keys(data.relationships).forEach(function (key) {
                  (Array.isArray(data.relationships[key].data) ? data.relationships[key].data : [data.relationships[key].data]).forEach(function (data) {
                    if (data) {
                      if (included[data.type] && included[data.type][data.id]){
                        if (included[data.type][data.id].attributes) {
                          data.attributes = included[data.type][data.id].attributes;
                        }
                        if (included[data.type][data.id].relationships) {
                          data.relationships = included[data.type][data.id].relationships;
                        }
                        if (included[data.type][data.id].meta) {
                          data.meta = included[data.type][data.id].meta;
                        }
                      }
                    }
                  });
                });
              }
            });
          }
        }
        if (call) {
          call.apply(this, [{detail: res}]);
        }
        return this;
      }.bind(this));
      xhr.open('get', this.base_url + url, true);
      xhr.responseType = 'json';
      xhr.withCredentials = true;
      this.$root['ajax_count'] = this.$root.ajax_count + 1
      xhr.send(null);
    },
    getFile: function (url, call) {
      var xhr = new XMLHttpRequest();
      xhr.addEventListener('load', function () {
        var res = xhr.response;
        res.status_code = xhr.status;
        if (res.status_code == 401) {
          this.relogin();
        }
        if (this.user_session) {
          if (res.status_code == 403 && this.user_session.attributes && this.user_session.attributes.role_id == 13){
            this.not_in_charge_apartment();
          }
        }
        this.$root['ajax_count'] = this.$root.ajax_count - 1
        if (call) {
          call.apply(this, [{detail: (window.URL || window.webkitURL).createObjectURL(res)}]);
        }
        return this;
      }.bind(this));
      xhr.open('get', this.base_url + url, true);
      xhr.responseType = 'blob';
      xhr.withCredentials = true;
      this.$root['ajax_count'] = this.$root.ajax_count + 1
      xhr.send(null);
    },
    delete: function (url, call) {
      var xhr = new XMLHttpRequest();
      xhr.addEventListener('load', function () {
        var res = xhr.response || {};
        res.status_code = xhr.status;
        if (res.status_code == 401) {
          this.relogin();
        }
        if (this.user_session) {
          if (res.status_code == 403 && this.user_session.attributes && this.user_session.attributes.role_id == 13){
            this.not_in_charge_apartment();
          }
        }
        this.$root['ajax_count'] = this.$root.ajax_count - 1
        if (call) {
          call.apply(this, [{detail: res}]);
        }
        return this;
      }.bind(this));
      xhr.open('delete', this.base_url + url, true);
      xhr.responseType = 'json';
      xhr.withCredentials = true;
      this.$root['ajax_count'] = this.$root.ajax_count + 1
      xhr.send(null);
    },
    post: function (url, params, call) {
      var xhr = new XMLHttpRequest();
      xhr.addEventListener('load', function () {
        var res = xhr.response || {};
        res.status_code = xhr.status;
        if (res.status_code == 401) {
          this.relogin();
        }
        if (this.user_session) {
          if (res.status_code == 403 && this.user_session.attributes && this.user_session.attributes.role_id == 13){
            this.not_in_charge_apartment();
          }
        }
        this.$root['ajax_count'] = this.$root.ajax_count - 1
        if (call) {
          call.apply(this, [{detail: res}]);
        }
        return this;
      }.bind(this));
      xhr.open('POST', this.base_url + url, true);
      xhr.responseType = 'json';
      xhr.withCredentials = true;
      this.$root['ajax_count'] = this.$root.ajax_count + 1;
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send(JSON.stringify(params));
    },
    put: function (url, params, call) {
      var xhr = new XMLHttpRequest();
      xhr.addEventListener('load', function () {
        var res = xhr.response || {};
        res.status_code = xhr.status;
        if (res.status_code == 401) {
          this.relogin();
        }
        if (this.user_session) {
          if (res.status_code == 403 && this.user_session.attributes && this.user_session.attributes.role_id == 13){
            this.not_in_charge_apartment();
          }
        }
        this.$root['ajax_count'] = this.$root.ajax_count - 1
        if (call) {
          call.apply(this, [{detail: res}]);
        }
        return this;
      }.bind(this));
      xhr.open('PUT', this.base_url + url, true);
      xhr.responseType = 'json';
      xhr.withCredentials = true;
      xhr.setRequestHeader('Content-Type', 'application/json');
      this.$root['ajax_count'] = this.$root.ajax_count + 1
      xhr.send(JSON.stringify(params));
    },
    silentGet: function (url, call) {
      var xhr = new XMLHttpRequest();
      xhr.addEventListener('error', function () {
        var res = xhr.response || {};
        res.status_code = xhr.status;
        if (res.status_code == 401) {
          this.relogin();
        }
        if (this.user_session) {
          if (res.status_code == 403 && this.user_session.attributes && this.user_session.attributes.role_id == 13){
            this.not_in_charge_apartment();
          }
        }
        res.error = {
          messages: ['サーバーエラー'],
        };
        if (call) {
          call.apply(this, [{detail: res}]);
        }
      }.bind(this));
      xhr.addEventListener('load', function () {
        var res = xhr.response || {};
        res.status_code = xhr.status;
        if (res.status_code == 401) {
          this.relogin();
        }
        if (this.user_session) {
          if (res.status_code == 403 && this.user_session.attributes && this.user_session.attributes.role_id == 13){
            this.not_in_charge_apartment();
          }
        }
        if (res.errors == undefined) {
          var included = {};
          if (res.included) {
            res.included.forEach(function (inc) {
              included[inc.type] = included[inc.type] || {};
              included[inc.type][inc.id] = inc;
            });
            Object.keys(included).forEach(function (key) {
              Object.keys(included[key]).forEach(function (id) {
                if (included[key][id].relationships) {
                  Object.keys(included[key][id].relationships).forEach(function (rel) {
                    (Array.isArray(included[key][id].relationships[rel].data) ? included[key][id].relationships[rel].data : [included[key][id].relationships[rel].data]).forEach(function (data) {
                      if (data) {
                        if (included[data.type] && included[data.type][data.id]){
                          if (included[data.type][data.id].attributes) {
                            data.attributes = included[data.type][data.id].attributes;
                          }
                          if (included[data.type][data.id].relationships) {
                            data.relationships = included[data.type][data.id].relationships;
                          }
                          if (included[data.type][data.id].meta) {
                            data.meta = included[data.type][data.id].meta;
                          }
                        }
                      }
                    });
                  });
                }
              });
            });
            (Array.isArray(res.data) ? res.data : [res.data]).forEach(function (data) {
              if (data.relationships) {
                Object.keys(data.relationships).forEach(function (key) {
                  (Array.isArray(data.relationships[key].data) ? data.relationships[key].data : [data.relationships[key].data]).forEach(function (data) {
                    if (data) {
                      if (included[data.type] && included[data.type][data.id]){
                        if (included[data.type][data.id].attributes) {
                          data.attributes = included[data.type][data.id].attributes;
                        }
                        if (included[data.type][data.id].relationships) {
                          data.relationships = included[data.type][data.id].relationships;
                        }
                        if (included[data.type][data.id].meta) {
                          data.meta = included[data.type][data.id].meta;
                        }
                      }
                    }
                  });
                });
              }
            });
          }
        }
        if (call) {
          call.apply(this, [{detail: res}]);
        }
        return this;
      }.bind(this));
      xhr.open('get', this.base_url + url, true);
      xhr.responseType = 'json';
      xhr.withCredentials = true;
      xhr.send(null);
    },
    ajaxSend: function (e) {
      if (e.currentTarget.dataset.posted != 'true') {
        e.currentTarget.dataset.posted = true;
        var form = e.target;
        var method = form.getAttribute('method');
        var type = form.dataset.type;
        method = method || 'GET';
        method = method.toUpperCase();
        if (form.tagName !== 'FORM') { return; }
        if (!form.action) { return; }
        var url = this.base_url + form.getAttribute('action');
        var fd = new FormData(form);
        var o = {};
        var n = {};
        fd.forEach(function (val, key) {
          if (n[key] || val == '__Array__') {
            if (typeof n[key] == 'string') {
              n[key] = [n[key], val];
            } else if (val == '__Array__') {
              n[key] = new Array();
            } else {
              n[key].push(val);
            }
          } else {
            //n[key] = (/^[1-9]\d*?$/).test(val) ? Number(val) : val;
            n[key] = val;
          }
        });
        Object.keys(n).forEach(function (key) {
          var val = n[key];
          key.split('.').reduce(function (a, c, i, s) {
            if (!a[c]) {
              a[c] = (s.length - 1) == i ? val : {};
            }
            return a[c];
          }, o);
        });
        var xhr = new XMLHttpRequest();
        xhr.addEventListener('error', function () {
          var res = xhr.response || {};
          res.status_code = xhr.status;
          if (res.status_code == 401) {
            this.relogin();
          }
          if (this.user_session) {
            if (res.status_code == 403 && this.user_session.attributes && this.user_session.attributes.role_id == 13){
              this.not_in_charge_apartment();
            }
          }
          res.error = {
            messages: ['サーバーエラー'],
          };
          this.$root['ajax_count'] = this.$root.ajax_count - 1
          form.dispatchEvent(new CustomEvent('ajax-load', { detail: res }));
        }.bind(this));

        xhr.addEventListener('load', function () {
          var res = xhr.response || {};
          res.status_code = xhr.status;
          if (res.status_code == 401) {
            this.relogin();
          }
          if (this.user_session) {
            if (res.status_code == 403 && this.user_session.attributes && this.user_session.attributes.role_id == 13){
              this.not_in_charge_apartment();
            }
          }
          res.request_payload = (arguments[1] || o);
          this.$root['ajax_count'] = this.$root.ajax_count - 1
          form.dispatchEvent(new CustomEvent('ajax-load', { detail: res }));
        }.bind(this));
        if (method === 'get') {
          var oField, sFieldType, nFile, sSearch = '';
          for (var nItem = 0; nItem < form.elements.length; nItem++) {
            oField = form.elements[nItem];
            if (!oField.hasAttribute('name')) { continue; }
            sFieldType = oField.nodeName.toUpperCase() === 'INPUT' ? oField.getAttribute('type').toUpperCase() : 'TEXT';
            if (sFieldType === 'FILE') {
              for (nFile = 0; nFile < oField.files.length;
                sSearch += '&' + escape(oField.name) + '=' + escape(oField.files[nFile++].name));
            } else if ((sFieldType !== 'RADIO' && sFieldType !== 'CHECKBOX') || oField.checked) {
              sSearch += '&' + escape(oField.name) + '=' + escape(oField.value);
            }
          }
          xhr.open('get', url.replace(/(?:\?.*)?$/, sSearch.replace(/^&/, '?')), true);
          xhr.responseType = 'json';
          xhr.withCredentials = true;
          this.$root['ajax_count'] = this.$root.ajax_count + 1
          xhr.send(null);
        } else {
          xhr.open(method, url);
          xhr.responseType = 'json';
          xhr.withCredentials = true;
          this.$root['ajax_count'] = this.$root.ajax_count + 1
          if (type == 'FormData') {
            xhr.send(fd);
          } else {
            xhr.setRequestHeader('Content-Type', 'application/json');
            //console.log(o);
            xhr.send(JSON.stringify(arguments[1] || o));
          }
        }
      }
      e.stopPropagation();
      e.preventDefault();
    },
    ajaxSearch: function (e) {
      var form = e.currentTarget;
      var fd = new FormData(form);
      var query = [];
      fd.forEach(function (val, key) {
        if (val != '') {
          query.push([key, val].join('='));
        }
      });
      this.$router.push(form.getAttribute('action') + '?' + query.join('&'), () => {}, () => {});
      form.dispatchEvent(new CustomEvent('params-end'));
      e.stopPropagation();
      e.preventDefault();
    },
    // 指定した日付の時刻を取得するが不正な場合はその月の最終日を取得する
    getValidDate: function (year, month, day) {
      let date = DateTime.fromObject({year: year, month: month, day: day}, {zone: 'Asia/Tokyo'});
      if (date.isValid) { return date }

      date = DateTime.fromObject({year: year, month: month, day: 1}, {zone: 'Asia/Tokyo'}).endOf('month')
      if (date.isValid) { return date }

      return DateTime.now().setZone('Asia/Tokyo')
    },
    formatDate: function (date, delim, week) {
      date = new Date(date);
      delim = delim || '-';
      return [date.getFullYear(), ('0' + (date.getMonth() + 1)).slice(-2), ('0' + date.getDate()).slice(-2)].join(delim) + (week ? '（' + this.weeks[date.getDay()] + '）' : '');
    },
    formatJSTDate: function (dateISOstring, opt) {
      let date = DateTime.fromISO(dateISOstring, {zone: 'Asia/Tokyo'})
      opt = opt || {year: 'numeric', month: 'long', day: 'numeric'}
      return date.setLocale('ja').toLocaleString(opt)
    },
    formatJSTTime: function (dateISOstring, opt) {
      let date = DateTime.fromISO(dateISOstring, {zone: 'Asia/Tokyo'})
      opt = opt || {hour: '2-digit', minute: '2-digit'}
      return date.setLocale('ja').toLocaleString(opt)
    },
    formatJSTDateTime: function (dateISOstring, opt) {
      let date = DateTime.fromISO(dateISOstring, {zone: 'Asia/Tokyo'})
      opt = opt || {year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit'}
      return date.setLocale('ja').toLocaleString(opt)
    },
    formatISODate: function (dateISOstring) {
      let date = DateTime.fromISO(dateISOstring, {zone: 'Asia/Tokyo'})
      return date.toISODate()
    },
    convertISODateTime: function (dateISOstring, hour, minute) {
      // 引数検証
      const date = DateTime.fromISO(dateISOstring, { zone: 'Asia/Tokyo' })
      if (date.invalid != null || hour == null || hour === '' || minute == null || minute === '') { return null }

      // 日付文字列 + 時 + 分 => ISO
      const convertTime = DateTime.fromObject({ year: date.year, month: date.month, day: date.day, hour: hour, minute: minute }, { zone: 'Asia/Tokyo' })

      return convertTime.invalid ? null : convertTime.toISO()
    },
    // 日付範囲文字列生成
    formatDateTimeRange: function(startISOstring, endISOstring, fullOpt) {
      let start = DateTime.fromISO(startISOstring, {zone: 'Asia/Tokyo'});
      let end = DateTime.fromISO(endISOstring, {zone: 'Asia/Tokyo'});
      fullOpt = fullOpt || {year: 'numeric', month: 'long', day: 'numeric', weekday: 'short', hour: '2-digit', minute: '2-digit'}
      let endOpt = Object.assign({}, fullOpt);
      if (end.hasSame(start, 'days')) {
        delete endOpt.year;
        delete endOpt.month;
        delete endOpt.day;
        delete endOpt.weekday;
        return start.setLocale('ja').toLocaleString(fullOpt) + ' ～ ' + end.setLocale('ja').toLocaleString(endOpt)
      } else if (end.hasSame(start, 'years')) {
        delete endOpt.year;
        return start.setLocale('ja').toLocaleString(fullOpt) + ' ～ ' + end.setLocale('ja').toLocaleString(endOpt)
      } else {
        return start.setLocale('ja').toLocaleString(fullOpt) + ' ～ ' + end.setLocale('ja').toLocaleString(fullOpt)
      }
    },
    formatUpdatedAt: function (datetimeISOstring) {
      let dt = DateTime.fromISO(datetimeISOstring, {zone: 'Asia/Tokyo'});
      return dt.invalid === null ? dt.setLocale('ja').toFormat('yyyy年M月d日(EEEEE) HH時mm分ss秒') : null;
    },
    settingAvailablePeriod: function (start_year, start_month, start_day, end_year, end_month, end_day, day_of_the_week = false) {
      return `${this.getSettingAvailableDateTime(start_year, start_month, start_day, day_of_the_week)} 〜 ${this.getSettingAvailableDateTime(end_year, end_month, end_day, day_of_the_week)}`
    },
    isValidAvailableDateTime: function (year, month, day) {
      if (year != null && (month == null || day == null)) return false

      if ((month == null && day != null) || month != null && day == null) return false

      const condition = {
        year: year,
        month: month,
        day: day != 0 ? day : 1
      }

      return !DateTime.fromObject(condition, { zone: 'Asia/Tokyo' }).invalid
    },
    getSettingAvailableDateTime: function (year, month, day, day_of_the_week = false) {
      if (year == null && month == null) return '';

      let date = null;
      let condition = { month: month };
      let opt = {month: 'long'};

      if (year != null) {
        condition["year"] = year;
        opt["year"] = 'numeric';
      }
      if (day != 0) {
        condition["day"] = day;
        opt["day"] = 'numeric';
      }
      let datetime = DateTime.fromObject(condition, {zone: 'Asia/Tokyo'})
      date = this.formatJSTDate(datetime.toISODate(), opt);
      if (day == 0) {
        date += '末日';
      } else if (year != null && day_of_the_week == true) {
        date += `(${datetime.weekdayShort})`
      }

      return date
    },
    getServiceName: function (service, serviceGroupPublicType) {
      let serviceName = service.name;
      if (!service.public_type || !serviceGroupPublicType) serviceName = `【非公開】${serviceName}`;
      return serviceName
    },
    getServiceGroupName: function (serviceGroup) {
      let serviceGroupName = serviceGroup.name;
      if (!serviceGroup.public_type) serviceGroupName = `【非公開】${serviceGroupName}`;
      return serviceGroupName
    },
    isPastSetting: function (period) {
      const now = DateTime.now()
      if (period.attributes.end_year == null && period.attributes.end_month == null && period.attributes.end_day == null) {
        return false
      }
      let start_at = DateTime.fromObject({
        year: period.attributes.start_year,
        month: period.attributes.start_month,
        day: period.attributes.start_day == 0 ? 1 : period.attributes.start_day,
      }, {zone: 'Asia/Tokyo'})
      if (period.attributes.start_day == 0) {
        start_at = start_at.endOf('month')
      }
      let end_at = DateTime.fromObject({
        year: period.attributes.end_year,
        month: period.attributes.end_month,
        day: period.attributes.end_day == 0 ? 1 : period.attributes.end_day,
      }, {zone: 'Asia/Tokyo'})
      if (period.attributes.end_day == 0) {
        end_at = end_at.endOf('month')
      }
      if (period.attributes.start_year == null && period.attributes.end_year == null) {
        return false
      }
      return end_at < now
    },
    hasPastSetting: function (collection) {
      return collection.some(ele => this.isPastSetting(ele))
    },
    // バイト単位変換
    formatByte: function (size) {
      var units = [" byte", " KB", " MB", " GB", " TB"];
      for (var i = 0; size > 1024; i++) {
        size /= 1024;
      }
      return (Math.round(size * 100) / 100).toLocaleString() + units[i];
    },
    // ISO書式の日付
    toISOFormat: function (date, param) {
      date = new Date(date) || new Date();
      if (date) {
        param = param || {};
        param.y = param.y != undefined ? Number(param.y) : date.getFullYear();
        param.m = param.m != undefined ? Number(param.m) : date.getMonth();
        param.d = param.d != undefined ? Number(param.d) : date.getDate();
        param.H = param.H != undefined ? Number(param.H) : date.getHours();
        param.M = param.M != undefined ? Number(param.M) : date.getMinutes();
        param.s = param.s != undefined ? Number(param.s) : date.getSeconds();
        param.ms = param.ms != undefined ? Number(param.ms) : date.getMilliseconds();
      }
      return new Date(param.y, param.m, param.d, param.H, param.M, param.s, param.ms).toISOString();
    },
    // ヒストリーバック
    backPage: function () {
      window.history.back();
    },
    // 指定要素位置へのスクロール
    scrollToElement: function (element) {
      window.scrollTo({ top: window.pageYOffset + element.getBoundingClientRect().top, left: 0 })
    },
    // 施設詳細画面へ遷移
    showServiceDetail: function (id) {
      this.$router.push(`/facility/services/${id}/detail`);
    },
    // サブメニュー展開
    toggleSubMenu: function (e) {
      e.currentTarget.classList.toggle('open');
    },
    // アイコンID取得
    getIconId: function (filename) {
      switch (filename.split('.').slice(-1)[0].toLowerCase()) {
        case 'bmp': {
          return 'ico-file-bmp';
        }
        case 'jpeg':
        case 'jpg': {
          return 'ico-file-jpg';
        }
        case 'gif': {
          return 'ico-file-gif';
        }
        case 'png': {
          return 'ico-file-png';
        }
        case 'pdf': {
          return 'ico-file-pdf';
        }
        case 'docx':
        case 'doc': {
          return 'ico-file-doc';
        }
        case 'pptx':
        case 'ppt': {
          return 'ico-file-ppt';
        }
        case 'xlsx':
        case 'xls': {
          return 'ico-file-xls';
        }
        case 'txt': {
          return 'ico-file-txt';
        }
        case 'zip': {
          return 'ico-file-zip';
        }
        default:
          return '';
      }
    },
    // バリデーションで無効時にエラーを追加
    addInvalid: function (e) {
      var elem = e.currentTarget;
      setTimeout(() => {
        if (window.pageYOffset > window.pageYOffset + elem.getBoundingClientRect().top) {
          window.scrollTo(0, elem.getBoundingClientRect().top + window.pageYOffset - 70);
        }
      }, 10);
      e.currentTarget.dataset.invalid = true;
      e.stopPropagation();
      e.preventDefault();
    },
    // 文中URLのリンク化
    autolink: function (text, flag) {
      if (text == null) {
        text = ''
      }
      // flag === true でサニタイズ
      if (flag) {
        const map = {
          "&": "&amp;",
          '"': "&quot;",
          "<": "&lt;",
          ">": "&gt;",
        }
        text = ' ' + text.replace(/[&"<>]/g, e => map[e]) + ' ';
      } else {
        text = ' ' + text + ' ';
      }
      text = text.replace(/<br>/g, ' <br> ');
      text = text.replace(/(^|\s|>)?(https?:\/\/[\x21-\x7e]+?)(?=($|\s|<))/gm, function (dummy0, prior, url) {
        if (url.match(location.host)) {
          return `${prior}<a href="${url}">${url}</a>`;
        } else {
          return `${prior}<a href="${url}" target="_blank">${url}</a>`;
        }
      });
      return text.trim();
    },
    // 改行文字(\n)を改行タグ(<br>)に変換
    changeLineBreakCode: function (text) {
      return text.replaceAll('\n', '<br>');
    },
    // セッション切れトップ移動
    relogin: function () {
      if (this.$route.name != 'A-0') {
        clearInterval(window.relogin);
        window.relogin = setTimeout((route_name) => {
          if (route_name != 'Home') {
            alert('セッションが切れました。再度ログインしてください。');
          }
          if (this.$route.name != 'A-0') {
            window.sessionStorage.setItem('last_access_url', this.$route.fullPath);
          }
          location.href = '/user_session/new';
        }, 200, this.$route.name);
      }
    },
    not_in_charge_apartment: function() {
      window.not_in_charge_apartment = setTimeout(() => {
        alert('担当のマンションを確認してください。');
        location.href = '/maint/portal';
      }, 200, this.$route.name);
    },
    // dataのリセット
    resetData: function () {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    // htmlサニタイズ
    sanitize_html: function(html) {
      if (html == null) {
        return ""
      }
      return (new String(html)).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#39;')
    },
    isAdmin: function(roleId) {
      return [1,10,12,13].includes(roleId)
    },
    isAdminParty: function(roleId) {
      return [1,10,11,12,13].includes(roleId)
    },
    isFacilityOperator: function(roleId) {
      return [1,5,6,7,10,11,12,13].includes(roleId)
    },
    isOperatable: function(user_id, role_id, item_creator_id) {
      return ([1,2,3,10,12,13].includes(role_id) || item_creator_id == user_id)
    },
    isApplyRole: function(roleId) {
      return [1,2,3,5,6,10,11,12,13].includes(roleId)
    },
    isMemberViewable: function(role_id) {
      return ![8,9].includes(role_id)
    },
    isAdminPartyCaretakers: function(roleId) {
      return [1,2,3,4,5,6,7,10,11,12,13].includes(roleId)
    },
    isAdminPartyUpperManagers: function(roleId) {
      return [1,2,3,10,11,12,13].includes(roleId)
    },
    isAdminPartyUpperManagersOperatable: function(user_id, role_id, item_creator_id) {
      return ([1,2,3,10,11,12,13].includes(role_id) || item_creator_id == user_id)
    },
    isAdminPartyUpperCaretakers: function(roleId) {
      return [1,2,3,5,6,10,11,12,13].includes(roleId)
    },
    isExecutives: function(roleId) {
      return [1,2,10,11,12,13].includes(roleId)
    },
    isAdminPartyMasters: function(roleId) {
      return [1,2,5,10,11,12,13].includes(roleId)
    },
    isAdminCaretakers: function(roleId) {
      return [1,2,3,4,5,6,7,10,12,13].includes(roleId)
    },
    isAdminUpperManagers: function(roleId) {
      return [1,2,3,10,12,13].includes(roleId)
    },
    isAdminUpperCaretakers: function(roleId) {
      return [1,2,3,5,6,10,12,13].includes(roleId)
    },
    isMemberMaintainable: function(roleId) {
      return [1,2,3,5,10,11,12,13].includes(roleId)
    },
    // ハッシュタグ付きの文字列にして返却
    hashtagStr: function(str) {
      return `#\u{00A0}${str}`
    }
  },
  data: function () {
    return {
      base_url: '/api/v2',
      today: (function () {
        const date = DateTime.now().setZone('Asia/Tokyo')
        return {
          date: date,
          key: date.toISODate(),
          y: date.year,
          m: date.month,
          d: date.day,
        }
      })(),
      weeks: ['日', '月', '火', '水', '木', '金', '土', '日'],
      role_name: [null, null, '管理者マスター', '管理者Ａ', '管理者Ｂ', '外部協力マスター', '外部協力Ａ', '外部協力Ｂ', '居住者Ａ', '居住者Ｂ'],
      kind_name: [null, '１．区分所有者', '２．外部区分所有者', '３．賃借人', '４．協力会社'],
      status: {
        notice: ['未選択', '重要', '緊急'],
        opinion: ['未回答', '回答済', '終了'],
        survey: ['未回答', '回答済', '作成中', '対象外'],
        reservation: [null, '仮予約(抽選待ち)', '仮予約(承認待ち)', '確定(未入金)', '確定(入金済)', '落選', 'キャンセル'],
        use: {
          out_used: '対象外',
          first_come: '先着受付中',
          approval: '承認制受付中',
          lottery: '抽選受付中',
          before_apply: '受付開始前',
          after_apply: '受付終了',
          elective_period: '抽選作業中',
          stop_apply: '対象外',
          out_service_use: '対象外',
          stop_service: '対象外',
          readonly: '閲覧のみ',
          invalid: '対象外',
        },
      },
      stay_in: ['マンション内' , 'マンション外', '未確認'],
      need_help: ['要支援', '支援不要', '未確認'],


      //ckeditor
      editorType: ClassicEditor,
      editorData: '',
      editorConfig: {
        language: 'ja',
        plugins: [
          Essentials,
          Bold,
          Italic,
          Strikethrough,
          Underline,
          List,
          Paragraph,
          Blockquote,
          Alignment,
          Fontcolor,
          Fontsize,
          Horizontalline,
          ],
        fontSize: {
          options: ['default', 8, 10, 12, 14, 16, 18, 20, 24, 28, 32, 36],
        },
        toolbar: [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          '|',
          'bulletedList',
          'numberedList',
          '|',
          'blockQuote',
          '|',
          'alignment',
          '|',
          'horizontalline',
          '|',
          'fontcolor',
          'fontsize',
          '|',
          'undo',
          'redo',
         ]
      },

      luxon_format: {
        short_date: {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'},
        time_seconds: {hour: '2-digit', minute: '2-digit', second: '2-digit'}
      }
    }
  }
};
